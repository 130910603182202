<template>
    <div class="h-full pb-10">
        <div class="content bg-white shadow rounded mt-2 p-3 h-full">

        </div>
    </div>
</template>
<script>
import {defineComponent} from 'vue';
export default defineComponent({
    data() {
        return {

        }
    },
});
</script>